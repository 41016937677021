import React from "react"

import Layout from "../components/layout/index"
import Seo from "../components/seo"
import { LearnComponent } from "../components/learn/index"

const Learn = () => {
  return (
    <Layout>
      <Seo title="Steam" />
      <LearnComponent />
    </Layout>
  )
}

export default Learn
