import { Link } from "gatsby"
import React from "react"
// import * as style from "./learn.module.css"

import background_image from "../../assets/elearningImage.png"
import background_image2 from "../../assets/study2.png"
import Button from "../buttons/overview_button/index"
import { CategoryLayout } from "../category_layout"

export const LearnComponent = () => {
  const link = "https://edu.angazaelimu.com"

  return (
    <>
      <CategoryLayout image={background_image} className="mb-24 gap-y-20">
        <div className="flex flex-col w-10/12 mx-auto md:mx-0 md:w-9/12 text-center md:text-left">
          <h1 className="text-[2.5rem] font-bold">E-Learning</h1>
          <p className="my-3 text-neutral-main mb-8">
            Get access to quality Lesson Notes, KCSE & KCPE past papers, quizes
            and assignments, perfomance progress review, and discussion forums.
          </p>

          <a href={link + "/signup"}>
            <Button text="Start learning" />
          </a>
          {/* <a href={link + "/signup?user_type=teacher"}>
          <Button text="Teachers" />
        </a>
        <a href={link + "/signup?user_type=parent"}>
          <Button text="Parents" link="/" />
        </a> */}
        </div>
      </CategoryLayout>

      <div className="px-5 py-32 md:pb-24 md:py-40">
        <h1 className="text-[2.5rem] font-bold text-center md:text-left px-5 mb-10 md:mb-0">
          Why choose us?
        </h1>

        <CategoryLayout image={background_image2} className="mt-0 gap-5">
          <div className="flex flex-col md:w-9/12 gap-8">
            <div className="">
              <h3 className="text-2xl md:text-xl font-medium mb-3 text-center md:text-left">
                Personalized & Interactive content
              </h3>
              <p className="text-neutral-500/95 text-sm -mt-2 text-center md:text-left">
                Get insights and learning plans tailored to your individual
                learning needs. Engage in discussions with students and teachers
                and have fun as you learn.
              </p>
            </div>

            <div className="">
              <h3 className="text-2xl md:text-xl font-medium mb-3 text-center md:text-left">
                Curriculum-aligned notes & assessments
              </h3>
              <p className="text-neutral-500/95 text-sm -mt-2 text-center md:text-left">
                Don’t waste time learning material you will neither need nor
                apply. Our notes and assessments are created for the current
                Kenyan curriculum. Additionally, access quizzes, tests, and
                previous years’ KCPE/KCSE past papers In all subjects.
              </p>
            </div>

            <div className="">
              <h3 className="text-2xl md:text-xl font-medium mb-3 text-center md:text-left">
                Convenient online/off-line access
              </h3>
              <p className="text-neutral-500/95 text-sm -mt-2 text-center md:text-left">
                Worrying about poor internet or not always having enough
                bundles? We have made our e-learning mobile app accessible
                without WiFi or cellular service. Thus, you can learn from
                anywhere, without struggling to buy those bundles!
              </p>
            </div>
          </div>
        </CategoryLayout>

        <div className="my-6 md:my-12 lg:my-20 mt-32 lg:mt-48 rounded-2xl bg-primary-main flex flex-col items-center justify-center flex-wrap gap-6 text-light py-8 md:py-14 px-5 md:px-16 md:w-10/12 mx-auto">
          <h3 className="font-semibold text-xl text-center md:text-3xl">
            Start learning with us today!
          </h3>
          <Link to={link}>
            <Button text="Sign up" className="border border-light" />
          </Link>
        </div>
      </div>
    </>
  )
}
